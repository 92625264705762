module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-XXXXXX-X"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Charlie Trotter Photography","short_name":"CTP","description":"Image-heavy photography portfolio with colorful accents & great typography","start_url":"/","background_color":"#3b3c4f","theme_color":"#db7436","display":"standalone","icon":"src/favicon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"33f1e7c6a9c6f8347618c8a08218f384"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
